import { format, parse, parseISO } from 'date-fns';

import { MultiEnvLogger } from './MultiEnvLogger';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, DEFAULT_TIME_FORMAT } from './date-util';
import { DefaultCalculationResults } from './default-calculation';

interface FormIOSelectionValues {
  label: string;
  value: string;
  shortcut?: string;
}
type FormIOFieldType =
  | 'anxietyScale'
  | 'bodyMap'
  | 'button'
  | 'checkbox'
  | 'checkmatrix'
  | 'columns'
  | 'csaSelectBoxes'
  | 'csaDateTime'
  | 'container'
  | 'content'
  | 'datagrid'
  | 'datetime'
  | 'day'
  | 'editgrid'
  | 'email'
  | 'file'
  | 'htmlelement'
  | 'number'
  | 'numericTracker'
  | 'painScale'
  | 'panel'
  | 'phoneNumber'
  | 'verifyAddress'
  | 'radio'
  | 'rangeScale'
  | 'select'
  | 'selectboxes'
  | 'summaryEditGrid'
  | 'summaryInput'
  | 'summaryContainer'
  | 'textarea'
  | 'textfield'
  | 'time'
  | 'wongBakerScale'
  | 'youtubeVideoEducation'
  | 'verticalScale'
  | 'metricImperial'
  | 'url';

const UNIT_OF_MEASURE_KEY = 'unitOfMeasure';
const INJECT_DEFAULT_KEY = 'injectDefault';

/**
 * Describes the form io format we find - only fields we might care about
 */
export interface FormIOFormFieldComponent {
  label: string;
  key: string;
  type: FormIOFieldType;
  inputType?: 'textfield' | 'text' | 'number' | 'checkbox'; // textField == normal input, text==textarea NOt consistently there, so we'll ignore
  inputFormat?: 'plain' | 'html'; // html was in text area, plan (text and number)
  data?: { values: FormIOSelectionValues[] }; // in data select when type=select
  values?: FormIOSelectionValues[]; // at component level select when type=radio
  content?: string; // HTML content
  properties?: { [key: string]: string };
  components?: FormIOFormFieldComponent[];
  columns?: { components?: FormIOFormFieldComponent[] }[];
  defaultValue?: any;
  contentUrls?: FormIOContentUrls | null;
}

export interface FormIOContentUrls {
  thumbnailUrl: string;
  originalUrl: string;
}

export interface FormIOForm {
  display?: 'wizard' | 'form';
  components?: FormIOFormFieldComponent[];
}

// Must match EnumDataModelFieldType in packages/api
export type FormFieldDataType =
  | 'TEXT'
  | 'NUMBER'
  | 'CHOICE'
  | 'CHOICES'
  | 'BOOLEAN'
  | 'DATE'
  | 'DATETIME'
  | 'TIME'
  | 'NONE'
  | 'ARRAY'
  | 'OBJECT'
  | 'FILE'
  | 'NO_VALUE';

/**
 * Our cleansed notion a form field
 */
export interface ChoiceValues {
  label: string;
  value: string;
}

export interface FormFieldComponent {
  key: string;
  dataType: FormFieldDataType;
  values?: ChoiceValues[];
  unitOfMeasure?: string;
  label?: string;
  format?: string;
  defaultValue?: any;
}

export interface FormDataModel {
  fields: FormFieldComponent[];
}

export enum FormSystemField {
  _submissionDate = '_submissionDate',
  _submitted = '_submitted',
  _assignmentDate = '_assignmentDate,',
  _assigned = '_assigned',
  _reasonForAssignment = '_reasonForAssignment',
}

const DEBUG = false;

export class FormParser {
  public static textToJson(input: string): any {
    const cleansedText = input.replace(/\\/g, '');
    if (cleansedText === '') {
      return {};
    }
    try {
      return JSON.parse(cleansedText);
    } catch (e) {
      MultiEnvLogger.error('FormParser.textToJson', `Error Parsing text. Input text was:\n${input}\n\n cleansed:\n${cleansedText}`);
      throw e;
    }
  }

  public static parseForm(formName: string, input: FormIOForm): FormDataModel {
    const location = 'FormParser.parseForm';
    let formIOForm: FormIOForm = {};
    try {
      formIOForm = typeof input === 'string' ? JSON.parse(input) : input;
    } catch (e) {
      MultiEnvLogger.error(location, `Error parsing JSON in formName ${formName}. input: ${input}`, e);
      try {
        MultiEnvLogger.log(location, 'trying special character removal as a stop gap');
        formIOForm = typeof input === 'string' ? FormParser.textToJson(input) : input;
      } catch (e) {
        MultiEnvLogger.error(location, `Error FormParser.textToJson() in formName ${formName}`, e);
      }
    }
    if (!formIOForm.components) {
      return { fields: [] };
    }
    const fields = FormParser.recursivelyParseComponentList(formName, formIOForm.components);
    fields.push({ dataType: 'BOOLEAN', label: 'Submitted', key: FormSystemField._submitted });
    fields.push({ dataType: 'DATETIME', label: 'Submission Date', key: FormSystemField._submissionDate });
    // TODO: Make assignment system fields work
    // fields.push({ dataType: 'BOOLEAN', label: 'Assigned', key: FormSystemField._assigned });
    // fields.push({ dataType: 'DATETIME', label: 'Assignment date', key: FormSystemField._assignmentDate });
    // fields.push({ dataType: 'TEXT', label: 'Reason for Assignment', key: FormSystemField._reasonForAssignment });
    return { fields };
  }

  private static recurseComponentList(
    components: FormIOFormFieldComponent[],
    callback: (component: FormIOFormFieldComponent) => void
  ): void {
    // Add direct list
    components.forEach((c) => callback(c));
    // recurse
    components.forEach((c) => {
      if (c.components) {
        FormParser.recurseComponentList(c.components, callback);
      }
      if (c.columns) {
        for (const col of c.columns) {
          if (col.components) {
            FormParser.recurseComponentList(col.components, callback);
          }
        }
      }
    });
  }
  private static recursivelyParseComponentList(formName: string, components: FormIOFormFieldComponent[]): FormFieldComponent[] {
    const result: FormFieldComponent[] = [];
    FormParser.recurseComponentList(components, async (c) => result.push(...FormParser.transformField(formName, c)));
    return result;
  }

  private static findMatchingEndBrace(input: string, startIndex: number): number {
    // find matching end brace
    let braceCount = 1;
    for (let i = startIndex + 1; i < input.length; i++) {
      if (input[i] === '{') {
        braceCount++;
      } else if (input[i] === '}') {
        braceCount--;
        if (braceCount === 0) {
          return i;
        }
      }
    }
    return -1;
  }

  public static async scanStringForSubstitutions(
    str: string | undefined,
    expressionEvaluation: (expression: string) => Promise<string>
  ): Promise<string | undefined> {
    DEBUG && MultiEnvLogger.debug(`scanStringForSubstitutions start`, `str=${str}`);
    if (!str) {
      return undefined;
    }
    let pos = str.indexOf('${');
    while (pos !== -1) {
      const end = FormParser.findMatchingEndBrace(str, pos + 2);
      if (end !== -1) {
        const expression = str.substring(pos + 2, end);
        const value = await expressionEvaluation(expression);
        DEBUG && MultiEnvLogger.debug(`scanStringForSubstitutions`, `${JSON.stringify({ pos, end, expression, value })}`);
        str = str.replace('${' + expression + '}', value);
      } else {
        const errorInsert = 'no matching brace for -> ${';
        str = str.replace('${', errorInsert);
        pos = pos + errorInsert.length + 1;
      }
      pos = str.indexOf('${', pos + 2);
      DEBUG && MultiEnvLogger.debug(`scanStringForSubstitutions`, `${JSON.stringify({ str, pos })}`);
    }
    return str;
  }

  public static async scanFormForSubstitutions(
    formInput: FormIOForm,
    expressionEvaluation: (expression: string) => Promise<string>,
    defaultCalculationResults: DefaultCalculationResults
  ): Promise<FormIOForm> {
    const location = 'FormParser.scanFormForSubstitutions';
    DEBUG && MultiEnvLogger.debug(`${location}- started`, JSON.stringify({ formInput, defaultCalculationResults }));
    const retVal = JSON.parse(JSON.stringify(formInput));

    if (retVal.components) {
      const promises: Promise<any>[] = []; // Can be deleted when INJECT_DEFAULT_KEY removed
      FormParser.recurseComponentList(retVal.components, async (component) => {
        // Does this have a calculated default?
        const defaultCalculation = defaultCalculationResults[component.key];
        if (defaultCalculation) {
          component.defaultValue = defaultCalculation;
        }

        DEBUG &&
          MultiEnvLogger.debug(
            `${location}- component`,
            JSON.stringify({ key: component.key, defaultCalculation: defaultCalculation || 'none' })
          );

        const defaultExpression = component.properties ? component.properties[INJECT_DEFAULT_KEY] : undefined; // deprecated
        if (defaultExpression) {
          try {
            const promise = expressionEvaluation(defaultExpression);
            promises.push(promise);
            promise.then((defaultValue: any) => {
              DEBUG &&
                MultiEnvLogger.debug(
                  `${location}- component default`,
                  JSON.stringify({ defaultExpression, defaultValue, typeOf: typeof defaultValue }, null, 2)
                );
              component.defaultValue = defaultValue;
            });
          } catch (e) {
            MultiEnvLogger.error(location, `Error calculating default value with expression '${defaultExpression}'`, e);
          }
        }
      });
      await Promise.all(promises);
    }

    return retVal;
  }

  private static transformField(formName: string, formIoField: FormIOFormFieldComponent): FormFieldComponent[] {
    const dataType = FormParser.convertFormIoTypeToDataType(formIoField.type);
    if (dataType === 'NONE') {
      MultiEnvLogger.error(
        'FormParser.transformField',
        `Unexpected form.io type in form ${formName} type: ${formIoField.type}. key = ${formIoField.key}  label = ${formIoField.label} Using NONE`
      );
    }
    const { key } = formIoField;
    if (!dataType) {
      const error = `Unexpected type of '${formIoField.type}' found. key=${key} form=${formName}`;
      throw new Error(error);
    }
    let unitOfMeasure: string | undefined = undefined;
    if (formIoField.properties) {
      unitOfMeasure = formIoField.properties[UNIT_OF_MEASURE_KEY];
    }
    const values = FormParser.cleanValues(formIoField.values ? formIoField.values : formIoField.data?.values);
    const retVal: FormFieldComponent[] = [
      { key, dataType, values, unitOfMeasure, label: formIoField.label || '', defaultValue: formIoField.defaultValue },
    ];
    if (formIoField.type === 'selectboxes') {
      if (!values || !values.length) {
        throw new Error(`selectboxes field does not have any values. ${JSON.stringify(formIoField)}`);
      }
      values.forEach((v) => {
        retVal.push({
          dataType: 'BOOLEAN',
          key: FormParser.choicesSubFieldName(key, v.value),
          label: FormParser.choicesSubFieldName(formIoField.label || '', v.label),
        });
      });
    }
    return retVal;
  }

  public static choicesSubFieldName(fieldKey: string, valueKey: string): string {
    return `${fieldKey}_${valueKey}`;
  }

  public static choicesSubFieldLabel(fieldLabel: string, valueLabel: string): string {
    return `${valueLabel}`;
  }

  private static cleanValues(values: FormIOSelectionValues[] | undefined): ChoiceValues[] | undefined {
    if (!values) {
      return undefined;
    }
    return values.map((v) => {
      return { label: v.label, value: v.value };
    });
  }

  public static convertFormIoTypeToDataType(formIoType: FormIOFieldType): FormFieldDataType | undefined {
    switch (formIoType) {
      case 'button':
      case 'checkbox':
        return 'BOOLEAN';
      case 'container':
      case 'summaryContainer':
      case 'summaryEditGrid':
        return 'OBJECT';
      case 'number':
      case 'numericTracker':
      case 'anxietyScale':
      case 'painScale':
      case 'wongBakerScale':
      case 'rangeScale':
      case 'verticalScale':
      case 'metricImperial':
        return 'NUMBER';
      case 'select':
      case 'radio':
        return 'CHOICE';
      case 'bodyMap':
      case 'selectboxes':
      case 'checkmatrix':
      case 'csaSelectBoxes':
        return 'CHOICES';
      case 'columns':
      case 'textarea':
      case 'textfield':
      case 'summaryInput':
        return 'TEXT';
      case 'csaDateTime':
      case 'datetime':
      case 'day':
        return 'DATETIME';
      case 'time':
        return 'TIME';
      case 'phoneNumber':
      case 'verifyAddress':
      case 'email':
        return 'TEXT';
      case 'datagrid':
      case 'editgrid':
        return 'ARRAY';
      case 'panel':
      case 'htmlelement':
      case 'content':
        return 'NO_VALUE';
      case 'url':
        return 'TEXT';
      case 'file':
        return 'FILE';
      default:
        return 'NONE';
    }
  }

  public static toDate(value: any, fieldType: FormFieldDataType): Date | undefined {
    if (!value) {
      return undefined;
    }
    const location = 'FormParser.toDate';
    try {
      switch (fieldType) {
        case 'DATETIME':
          return new Date(value);
        case 'DATE':
          console.log('FormParser.toDate', { value, fieldType });
          // format is MM/DD/YYYY
          if (value === '00/00/0000') {
            return undefined;
          }
          // if it's an ISO date, return it
          if (value.includes('T')) {
            console.log('FormParser.toDate', { value, fieldType, isoDate: new Date(value) });
            return new Date(value);
          }
          const parts = value.split('/');
          return new Date(Number(parts[2]), Number(parts[0]) - 1, Number(parts[1]));
        case 'TIME': // to do.  Not sure of format
          return new Date(value);
        default:
          MultiEnvLogger.error(location, `Unexpected field.dataType '${fieldType}'`);
          return undefined;
      }
    } catch (e) {
      MultiEnvLogger.error(location, `Unexpected error processing ${JSON.stringify(fieldType)}`, e);
      return undefined;
    }
  }

  public static formatValue(value: any, field: FormFieldComponent): string {
    if (!value) {
      return '';
    }
    const location = 'FormParser.formatValue';
    const fieldName = field.label ? field.label : field.key;
    DEBUG && MultiEnvLogger.debug(location, JSON.stringify({ field, value }, null, 2));
    function stringifyIfNeeded(v: any): string {
      return typeof v === 'string' ? v : JSON.stringify(v);
    }
    try {
      switch (field.dataType) {
        case 'CHOICE': {
          return field.values?.find((v) => v.value === value)?.label || JSON.stringify(value);
        }
        case 'DATETIME': {
          try {
            const date = new Date(value);
            if (field.format) {
              return format(date, field.format);
            }
            return format(date, DEFAULT_DATE_TIME_FORMAT);
          } catch (e) {
            MultiEnvLogger.error(location, `Error parsing DATETIME value '${value}' `, e);
            return 'Error';
          }
        }
        case 'DATE':
          try {
            const date = new Date(value);
            if (field.format) {
              return format(date, field.format);
            }
            return format(date, DEFAULT_DATE_FORMAT);
          } catch (e) {
            MultiEnvLogger.error(location, `Error parsing DATE value '${value}' `, e);
            return 'Error';
          }
        case 'TIME':
          try {
            const date = parse(value, 'HH:mm:ss', new Date());
            if (field.format) {
              return format(date, field.format);
            }
            return format(date, DEFAULT_TIME_FORMAT);
          } catch (e) {
            MultiEnvLogger.error(location, `Error parsing TIME value '${value}' `, e);
            return 'Error';
          }
        case 'BOOLEAN':
          return value ? 'Yes' : 'No';
        case 'CHOICES':
          try {
            const parsed = typeof value === 'string' ? JSON.parse(value) : value;

            const values: string[] = [];
            for (const key of Object.keys(parsed)) {
              const value = parsed[key] ? 'Yes' : 'No';
              values.push(`${fieldName} (${key}) = ${value}`);
            }
            return values.join(', ');
          } catch (e) {
            MultiEnvLogger.error(location, `Error parsing CHOICES value '${value}' `, e);
            return 'Error';
          }
        case 'NONE':
          return stringifyIfNeeded(value);
        case 'TEXT':
          return stringifyIfNeeded(value);
        case 'FILE':
          return stringifyIfNeeded(value);
        case 'NUMBER':
          return String(value); // To Do. # decimals according to unit of measure definition
        case 'ARRAY':
          return String(value);
        default:
          const error = `unexpected field.dataType '${field.dataType}'`;
          MultiEnvLogger.error(location, error);
          return error;
      }
    } catch (e) {
      MultiEnvLogger.error(location, `Unexpected error processing ${JSON.stringify(field)}`);
      return 'Error';
    }
  }

  public static serializeFormValue(value: any, fieldType: FormIOFieldType): string {
    return FormParser.serializeValue(value, FormParser.convertFormIoTypeToDataType(fieldType));
  }
  public static serializeValue(value: any, fieldType: FormFieldDataType | undefined): string {
    if (!fieldType) {
      return '';
    }
    try {
      switch (fieldType) {
        case 'ARRAY':
        case 'CHOICE':
        case 'CHOICES':
        case 'OBJECT':
        case 'BOOLEAN':
        case 'NUMBER':
          return JSON.stringify(value);
        case 'DATETIME':
        case 'DATE':
        case 'TIME':
          return value.toISOString();
        case 'TEXT':
          return value;
        case 'NONE':
        case 'NO_VALUE':
          return '';
        default:
          MultiEnvLogger.error('FormParser.serializeValue', `Unexpected fieldType '${fieldType}' `);
          return JSON.stringify(value);
      }
    } catch (e) {
      MultiEnvLogger.error('FormParser.serializeValue', `Error serializing value '${value}' `, e);
      return String(value);
    }
  }

  public static deserializeFormValue(value: any, fieldType: FormIOFieldType): string {
    return FormParser.deserializeValue(value, FormParser.convertFormIoTypeToDataType(fieldType));
  }

  public static deserializeValue(value: string | null | undefined, fieldType: FormFieldDataType | undefined): any {
    if (!value || !fieldType) {
      return value;
    }
    const location = 'FormParser.stringValueToTypedValue';
    try {
      switch (fieldType) {
        case 'ARRAY':
        case 'CHOICE':
        case 'CHOICES':
        case 'OBJECT':
        case 'BOOLEAN':
        case 'NUMBER':
          return JSON.parse(value);
        case 'DATETIME':
        case 'DATE':
        case 'TIME':
          return parseISO(value);
        case 'TEXT':
          return value;
        case 'NONE':
        case 'NO_VALUE':
          return '';
        default:
          const error = `unexpected field.dataType '${fieldType}'`;
          MultiEnvLogger.error(location, error);
          return value;
      }
    } catch (e) {
      MultiEnvLogger.error(location, `Unexpected error processing ${JSON.stringify(fieldType)}`, e);
      return value;
    }
  }
}
