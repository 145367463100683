import React from 'react';
import { AccountType } from 'csa-ui/src/lib/api/api-types';
import { Chip } from '@mui/material';
import { Role } from '../lib/verid-api/verid-api-types';
import { User } from 'csa-ui/src/lib/api/api-types';

interface IProps {
  user: User;
}

const cssClasses = {
  chip: {
    margin: '2px',
  },
};

export function AccountChips(props: IProps) {
  const { role, accountType, isTest, mfaTypes, disabled } = props.user;
  const mfa = (mfaTypes && mfaTypes.length > 0) || false;

  const acctType = (type: AccountType) => {
    switch (type) {
      case AccountType.NORMAL:
        return null;
      case AccountType.SINGLE_USE:
        return <Chip size="small" label="Singe Use" sx={cssClasses.chip} />;
      case AccountType.SINGLE_USE_INVITE:
        return <Chip size="small" label="Singe Use Invite" sx={cssClasses.chip} />;
      default:
        console.error('Unhandled account type', type);
        return null;
    }
  };

  const acctRole = (role: Role) => {
    switch (role) {
      case Role.ADMIN:
        return <Chip size="small" label="Admin" sx={cssClasses.chip} />;
      case Role.STAFF:
        return <Chip size="small" label="Staff" sx={cssClasses.chip} />;
      case Role.USER:
        return <Chip size="small" label="User" sx={cssClasses.chip} />;
      default:
        console.error('Unhandled use role', role);
        return null;
    }
  };

  return (
    <>
      {acctRole(role)}
      {isTest && <Chip size="small" label="Test User" sx={cssClasses.chip} />}
      {acctType(accountType)}
      {mfa && <Chip size="small" label="MFA" sx={cssClasses.chip} />}
      {disabled && <Chip size="small" color="error" label="Disabled" />}
    </>
  );
}
